@import '@/styles/_variables.scss';

.brandBox {
  position: relative;
  padding-top: 67.5%;
  border-radius: 26px;
  overflow: hidden;
  transform: translateZ(0);  
  &.all {          
    transition: opacity 0.3s;  
    .brandName {
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
    &:after {
      content: none;  
    }
    &:hover {
      opacity: 0.9;  
    }
    // @include ci-gradient-tri();
    background: linear-gradient(125deg, #FFFEB8 0%, #EFCB80 21%, #EFCB80 44%, #FFECA7 62%, #FFFFC9 83%, #FFEDAB 100%);
    box-shadow: 0px 3px 6px #00000029;
    &:hover {
      background: linear-gradient(125deg, darken(#FFFEB8, 8%) 0%, darken(#EFCB80, 8%) 21%, darken(#EFCB80, 8%) 44%, darken(#FFECA7, 8%) 62%, darken(#FFFFC9, 8%) 83%, darken(#FFEDAB, 8%) 100%);
    }
  }    
  &:hover {
    &:after {
      opacity: 1;
    }
    img {
      transform: scale(1.1);
    }
  }
  &:after {
    transition: opacity 0.4s;
    content: "";
    background: rgba($color: #000000, $alpha: 0.4);            
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease-out !important;
  }
  .brandName {      
    font-size: 20px;
    text-transform: uppercase;
    z-index: 1;
    position: absolute;
    bottom: 24px;
    left: 36px;
    font-weight: bold;
    color: white;
    letter-spacing: 0.08em;
    text-shadow: 0px 1px 4px rgba($color: #000000, $alpha: 0.3);
    max-width: calc(100% - 24%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 767px) {  
  .brandBox {
    border-radius: 20px;
    &:not(.all) {
      .brandName {
        left: 12%;
        bottom: 12%;
      }
    }
    .brandName {
      white-space: nowrap;
      font-size: 16px;
    }
  }
}

@media (max-width: 450px) {  
  .brandBox {
    .brandName {
      font-size: 12px;
    }
  }
}