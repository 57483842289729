@import '@/styles/_variables.scss';

.brandGrid {
  @include content-width();  
  position: relative;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  &.hasBanner {
    margin-top: -2.6%;
  }
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  padding-bottom: 30px;
}

@media (max-width: 767px) {  
  .brandGrid {    
    grid-template-columns: 1fr 1fr;
    gap: 3vw;    
    &.hasBanner {
      margin-top: -40px;
    }
  }    
}