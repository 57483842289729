@import '@/styles/_variables.scss';

.bannerWrap {
  width: 100%;
  margin: auto;
  .bannerItem {
    width: 100%;
    img {
      width: 100%;
    }
  } 
}

.content {
  font-size: 18px;
}