@import '@/styles/_variables.scss';

.wrapper {
  @include content-width();
  padding-top: 80px;
  color: $text-color;
}
.title {
  position: relative;
  text-align: center;
  h2 {
    font-size: 36px;
    font-weight: 400;
  }
  margin-bottom: 80px;
}
.listWrap {
  display: flex;
  justify-content: center;
}
.viewAllBtn {
  &:hover {
    color: $ci-accent;
  }
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  font-size: 20px;
}

.viewAllBtnBlock {
  &:hover {
    color: $ci-accent;
  }
  margin-top: -10px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  font-size: 20px;
}
.watchList {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  &.slide {
    .item {
      width: 100%;
    }
  }
}
.item {
  margin-bottom: 60px;  
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0 40px;
  text-align: center;  
  img {
    width: 100%;
  }
  .brand {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 22px;
    margin-top: 60px;
  }
  .model {
    font-weight: 300;
    font-size: 16px;
    margin-top: 20px;
  }
}

@media (max-width: 1280px) {
  .title {
    h2 {
      font-size: 3vw;
    }
  }
}

@media (max-width: 960px) {  
  .listWrap {
    &.slide {
      margin-right: -20px;
      margin-left: -20px;
    }
  }
  .watchList {    
    &.slide {           
      .item {                
        margin-right: 3vw;        
        width: 100%;        
      }
    }
    .item {
      padding: 0 20px;
    }
    &:not(.slide) {
      .item {
        width: 33.33%;
      }
    }    
  }
}

@media (max-width: 767px) {  
  .wrapper {
    padding-top: 8vw;
  }  
  .viewAllBtnBlock {
    display: none;
  }
  .viewAllBtn {
    margin: auto;
    margin-top: 30px; 
    right: 0;
    justify-content: center;
    position: relative;
  }
  .title {
    h2 {
      font-size: 5vw;
    }
    margin-bottom: 8vw;
  }
  .item {    
    font-size: 22px;
    margin-bottom: 12vw;
  }
  .item {
    padding: 0 10px;
    .brand {
      font-size: 4vw;
      margin-top: 6vw;
    }
    .model {
      font-size: 3vw;
      margin-top: 2vw;
    }
  }
  .watchList {        
    &:not(.slide) {
      .item {
        width: 50%;
        margin-bottom: 40px;
      }
    }    
  }
}

// @media (max-width: 600px) {
//   .watchList {
//     &.slide {
//       .item {
//         &:first-child {
//           margin-left: 26vw;
//         }        
//         min-width: 50vw;
//         margin-right: 3vw;
//       }
//     }
//   }
// }