@import '@/styles/_variables.scss';

.service {
  padding-top: 50px;
  padding-bottom: 60px;
}

.title {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
}

.services {
  margin-top: 40px;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  font-weight: bold;
  text-align: center;
  .box {    
    cursor: pointer;
    flex: 0 1 25%;
    transition: all 0.3s linear;
    &:hover {
      flex: 0 0 30%;      
    }
    .boxContent {      
      width: 100%;
      height: 400px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
      }    
      .boxText {
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 40px 20px;
        font-size: 18px;
        z-index: 1;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }  
}

@media (max-width: 940px) {
  .service {
    padding-top: 30px;
    .title {
      font-size: 26px;
    }
  }
  .services {    
    margin-top: 20px;
    flex-wrap: wrap;
    .box {
      .boxContent {
        height: 250px;
        .boxText {
          padding: 20px;
          padding-bottom: 30px;
          font-size: 14px;
        }
      }
      flex: 0 0 50%;
      &:hover {
        flex: 0 0 50%;
      }
    }    
  }
}